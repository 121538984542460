/**
 * Library used by client applications (brand) to handle user account features
 *
 */
(function (window) {

    function userAccountLib() {

        const DEFAULT_PROVINCE_CODE = 'ON';
        const DEFAULT_LANGUAGE = 'en';

        let UAEventEnum = Object.freeze({
            LOGIN: 0,
            LOGOUT: 1,
            USER_ACCOUNT_SUBSCRIBED: 2,
            USER_ACCOUNT_UPDATED: 3,
            USER_ACCOUNT_UNSUBSCRIBED: 4,
            INVENTORY_VEHICLE_ADDED: 10,
            INVENTORY_VEHICLE_REMOVED: 11,
            INVENTORY_VEHICLE_ALL_RETRIEVED: 12,
            INVENTORY_VEHICLE_UPDATED: 13,
            BUILT_VEHICLE_ADDED: 20,
            BUILT_VEHICLE_REMOVED: 21,
            BUILT_VEHICLE_ALL_RETRIEVED: 22,
            BUILT_VEHICLE_UPDATED: 23,
            BUILT_VEHICLE_RETRIEVED: 24,
            VIEWED_VEHICLE_ADDED: 30,
            VIEWED_VEHICLE_REMOVED: 31,
            VIEWED_VEHICLE_ALL_RETRIEVED: 32,
            VIEWED_VEHICLE_OTHER_USERS: 33,
            CUSTOM_ORDER_VEHICLE_ADDED: 40,
            CUSTOM_ORDER_VEHICLE_REMOVED: 41,
            CUSTOM_ORDER_VEHICLE_ALL_RETRIEVED: 42,
            CUSTOM_ORDER_VEHICLE_UPDATED: 43,
            CUSTOM_ORDER_VEHICLE_RETRIEVED: 44,
        });

        let uaLib = {};

        ///////////////////////////////////////////////////////////////////////
        //
        //
        //          Initialization declaration
        //
        //
        ///////////////////////////////////////////////////////////////////////

        // Default options
        let options = {

            //facebook options
            facebookAppId: '',
            facebookLoginButtonId: 'fca-ua-facebook-login-button',

            //google options
            googleClientId: '',
            googleLoginButtonId: 'fca-ua-google-login-button',

            //standalone auth options
            emailInputId: 'fca-ua-standalone-email',
            postalCodeInputId: 'fca-ua-standalone-postalCode',
            standaloneLoginButtonId: 'fca-ua-standalone-login-button',

            //api paths
            apiBaseUrl: 'http://localhost:8093',
            loginPath: '/api-ua/auth/login',
            logoutPath: '/api-ua/auth/logout',
            inventoryVehiclePath: '/api-ua/accounts/{id}/inventory-vehicles',
            builtVehiclePath: '/api-ua/accounts/{id}/built-vehicles',
            viewedVehiclePath: '/api-ua/accounts/{id}/viewed-vehicles',
            customOrderVehiclePath: '/api-ua/accounts/{id}/custom-order-vehicles',
            otherUsersViewsCountPath: '/api-ua/accounts/{id}/viewed-vehicles/other-users/{vin}',
            userAccountPath: '/api-ua/accounts/{id}',
            subscribePath: '/api-ua/accounts/{id}/subscribe',
            unsubscribePath: '/api-ua/accounts/{id}/unsubscribe',
            iframePath: '/iframe',

            // storage keys
            verifiedStorageKey: 'fca-ua-verifed',
            userStorageKey: 'fca-ua-user',
            inventoryVehiclesStorageKey: 'fca-ua-inventory-vehicles',
            builtVehiclesStorageKey: 'fca-ua-built-vehicles',
            customOrderVehiclesStorageKey: 'fca-ua-custom-order-vehicles',
            viewedVehiclesStorageKey: 'fca-ua-viewed-vehicles',
            viewedVehiclesNoUserStorageKey: 'fca-ua-viewed-vehicles-no-user',

            maxViewedVehicles: 10,

            debug: false,

            //if empty, iframe won't be loaded
            iframeDivId: 'fca-ua-iframe-container',
            onMessageFromIFrame: null,
        };

        ///////////////////////////////////////////////////////////////////////
        //
        //
        //                        PUBLIC FUNCTIONS
        //
        //
        ///////////////////////////////////////////////////////////////////////

        /**
         * Initialize library: initialize the iframe which handles login process
         * @param overriddenOptions overridden options
         * @param callbackAfterLogin function called after login (facebook, google, standalone)
         */
        uaLib.init = function (overriddenOptions, callbackAfterLogin) {

            $.extend(options, overriddenOptions);

            log(['Initializing UA library with: ', JSON.stringify(options)]);

            if (options.googleClientId !== '' && options.facebookAppId !== '') {

                initFacebook(callbackAfterLogin);

                initGoogle(callbackAfterLogin);

                initStandalone(callbackAfterLogin);

                initIFrame();

            } else {

                console.warn('UA -> facebookAppId and/or googleClientId are undefined.')
            }
        };

        /**
         * Clear user present in sessionStorage
         */
        uaLib.logout = function (successCallback) {
            //call the backend to remove the cookie
            api(options.logoutPath, "GET", {}, function () {
                //on response, clear the session storage
                clearSessionStorage();

                executeIfDefined(successCallback);
            });
        };

        /**
         * Return true if a user is present in session storage
         */
        uaLib.isUserLoggedIn = function () {
            return sessionStorage.getItem(options.userStorageKey) !== null;
        };

        /**
         * Return a Promise object with result
         */
        uaLib.retrieveAndCheckIsUserLoggedIn = function () {

            return new Promise((resolve, reject) => {

                //if user isn't present in session storage
                //if we didn't check yet if he has an account
                if (!uaLib.isUserLoggedIn() && !isUserVerified()) {

                    //make a backend call to get the user (using the cookie)
                    api(
                        options.loginPath,
                        "GET",
                        {},
                        (response) => {
                            //flag the user verification as done
                            window.FcaCookieChecker.addSessionStorage(options.verifiedStorageKey, "true");
                            if (isSuccess(response)) {
                                handleEvent(UAEventEnum.LOGIN, response.data);
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        },
                        () => resolve(false));
                } else {
                    resolve(uaLib.isUserLoggedIn());
                }
            });
        };

        /**
         * Return user in session storage
         */
        uaLib.getUser = () => {
            return JSON.parse(sessionStorage.getItem(options.userStorageKey));
        };

        /**
         *
         * @returns {Promise}
         */
        uaLib.getInventoryVehicles = () => {

            return getVehicles(options.inventoryVehiclePath, options.inventoryVehiclesStorageKey,
                UAEventEnum.INVENTORY_VEHICLE_ALL_RETRIEVED);
        };

        /**
         *
         * @returns {Promise}
         */
        uaLib.getBuiltVehicles = () => {

            return getVehicles(options.builtVehiclePath, options.builtVehiclesStorageKey,
                UAEventEnum.BUILT_VEHICLE_ALL_RETRIEVED);
        };

        /**
         *
         * @returns {Promise}
         */
        uaLib.getViewedVehicles = () => {

            return getVehicles(options.viewedVehiclePath, options.viewedVehiclesStorageKey,
                UAEventEnum.VIEWED_VEHICLE_ALL_RETRIEVED);
        };

        /**
         * Add a custom order vehicle in favorites of logged in user
         * @returns {Promise}
         */
        uaLib.addCustomOrderVehicle = function (customOrderVehicleForm) {
            return apiUserCallPromise(options.customOrderVehiclePath, "POST", customOrderVehicleForm,
                UAEventEnum.CUSTOM_ORDER_VEHICLE_ADDED);
        };

        /**
         * Retrieves custom order vehicles
         *
         * @returns {Promise}
         */
        uaLib.getCustomOrderVehicles = () => {
            return getVehicles(options.customOrderVehiclePath, options.customOrderVehiclesStorageKey, UAEventEnum.CUSTOM_ORDER_VEHICLE_ALL_RETRIEVED);
        };

        /**
         * @params vin
         * @returns {Promise}
         */
        uaLib.getOtherUsersViewsCount = (vin) => {

            return getOtherUsersViews(options.otherUsersViewsCountPath, options.userStorageKey,
                UAEventEnum.VIEWED_VEHICLE_OTHER_USERS, vin);
        };


        /**
         * Get viewed vehicles from local storage
         */
        uaLib.getViewedVehiclesFromLocalStorage = () => {
            return new Promise((resolve) => {
                let vehicles = JSON.parse(localStorage.getItem(options.viewedVehiclesNoUserStorageKey));
                //initialize the array if doesn't exist
                if (vehicles === null) {
                    vehicles = [];
                }
                // sort by last lastViewDate
                vehicles.sort((a, b) => {
                    let result = -1;
                    if (b.lastViewDate > a.lastViewDate) {
                        result = 1;
                    }
                    return result;
                });
                resolve(vehicles);
            });
        };

        /**
         * Add a inventory vehicle in favorites of logged in user
         * @returns {Promise}
         */
        uaLib.addInventoryVehicle = function (inventoryVehicleForm) {

            return apiUserCallPromise(options.inventoryVehiclePath, "POST", inventoryVehicleForm,
                UAEventEnum.INVENTORY_VEHICLE_ADDED);
        };

        /**
         * Update title and notes for an inventory vehicle
         * @returns {Promise}
         */
        uaLib.updateInventoryVehicle = function (inventoryVehicleId, propertiesForm) {

            return apiUserCallPromise(options.inventoryVehiclePath + "/" + inventoryVehicleId, "POST",
                propertiesForm, UAEventEnum.INVENTORY_VEHICLE_UPDATED);
        };

        /**
         * Remove the inventory vehicle in favorites of logged in user
         * @returns {Promise}
         */
        uaLib.removeInventoryVehicle = function (inventoryVehicleId) {

            return apiUserCallPromise(options.inventoryVehiclePath + "/" + inventoryVehicleId, "DELETE", null,
                UAEventEnum.INVENTORY_VEHICLE_REMOVED);
        };

        uaLib.removeInventoryVehicles = function (vehicleIds) {

            let removeVehicleForm = {ids: vehicleIds};
            return apiUserCallPromise(options.inventoryVehiclePath, "DELETE", removeVehicleForm,
                UAEventEnum.INVENTORY_VEHICLE_REMOVED);
        };

        /**
         * Get one built vehicle for the connected user.
         * @param builtVehicleId
         * @returns {Promise}
         */
        uaLib.getBuiltVehicle = function (builtVehicleId) {

            const vehicles = JSON.parse(sessionStorage.getItem(options.builtVehiclesStorageKey));
            if (vehicles !== null) {
                const vehicle = vehicles.find((element) => element.id === parseInt(builtVehicleId));
                if (vehicle) {
                    return new Promise((resolve, reject) => resolve(vehicle));
                }
            }
            return apiUserCallPromise(options.builtVehiclePath + "/" + builtVehicleId, "GET", null,
                UAEventEnum.BUILT_VEHICLE_RETRIEVED);
        };

        /**
         * Add a built vehicle in favorites of logged in user
         * @returns {Promise}
         */
        uaLib.addBuiltVehicle = function (builtVehicleForm) {

            return apiUserCallPromise(options.builtVehiclePath, "POST", builtVehicleForm,
                UAEventEnum.BUILT_VEHICLE_ADDED);
        };

        /**
         * Update title and notes for a built vehicle
         * @returns {Promise}
         */
        uaLib.updateBuiltVehicle = function (builtVehicleId, propertiesForm) {

            return apiUserCallPromise(options.builtVehiclePath + "/" + builtVehicleId, "POST", propertiesForm,
                UAEventEnum.BUILT_VEHICLE_UPDATED);
        };

        /**
         * Remove the built vehicle in favorites of logged in user
         * @returns {Promise}
         */
        uaLib.removeBuiltVehicle = function (builtVehicleId) {

            return apiUserCallPromise(options.builtVehiclePath + "/" + builtVehicleId, "DELETE", {},
                UAEventEnum.BUILT_VEHICLE_REMOVED);
        };

        uaLib.removeBuiltVehicles = function (vehicleIds) {

            let removeVehicleForm = {ids: vehicleIds};
            return apiUserCallPromise(options.builtVehiclePath, "DELETE", removeVehicleForm,
                UAEventEnum.BUILT_VEHICLE_REMOVED);
        };

        /**
         * Add a viewed vehicle in the list of logged in user
         * @returns {Promise}
         */
        uaLib.addViewedVehicle = (viewedVehicleForm) => {

            return apiUserCallPromise(options.viewedVehiclePath, "POST", viewedVehicleForm,
                UAEventEnum.VIEWED_VEHICLE_ADDED);
        };

        /**
         * Add a vehicle to local storage
         * @param viewedVehicleForm
         */
        uaLib.addViewedVehicleToLocalStorage = (viewedVehicleForm) => {
            let vehicles = JSON.parse(localStorage.getItem(options.viewedVehiclesNoUserStorageKey));
            //initialize the array if doesn't exist
            if (vehicles === null) {
                vehicles = [];
            }
            //check if vin is already present in the array
            let index = vehicles.findIndex((element) => {
                return element.vin === viewedVehicleForm.vin
            });
            if (index > -1) {
                //only update the last view date
                vehicles[index].lastViewDate = new Date().getTime();
            } else {
                //remove the older vehicles if max is reached
                if (vehicles.length >= options.maxViewedVehicles) {
                    vehicles.pop();
                }
                //add a new vehicle in the array
                vehicles.push({
                    id: new Date().getTime(),
                    vin: viewedVehicleForm.vin,
                    brandCode: viewedVehicleForm.brandCode,
                    lastViewDate: new Date().getTime()
                })
            }
            window.FcaCookieChecker.addLocalStorage(options.viewedVehiclesNoUserStorageKey, JSON.stringify(vehicles));
        };

        /**
         * Remove a list of viewed vehicles by id
         * @param vehicleIds
         */
        uaLib.removeViewedVehicles = function (vehicleIds) {

            let removeVehicleForm = {ids: vehicleIds};
            return apiUserCallPromise(options.viewedVehiclePath, "DELETE", removeVehicleForm,
                UAEventEnum.VIEWED_VEHICLE_REMOVED);
        };

        /**
         * Remove a list of viewed vehicles by id
         * @param vehicleIds
         */
        uaLib.removeViewedVehiclesFromLocalStorage = function (vehicleIds) {

            return new Promise((resolve) => {

                let vehicles = JSON.parse(localStorage.getItem(options.viewedVehiclesNoUserStorageKey));

                if (vehicleIds && vehicles !== null) {
                    vehicleIds.forEach((id) => {
                        let indexToRemove = vehicles.findIndex((vehicle) => {
                            return vehicle.id === id;
                        });
                        if (indexToRemove > -1) {
                            vehicles.splice(indexToRemove, 1);
                        }
                    });
                    window.FcaCookieChecker.addLocalStorage(options.viewedVehiclesNoUserStorageKey, JSON.stringify(vehicles));
                }
                resolve(null);
            });
        };

        /**
         * Remove a list of custom order vehicles by id
         *
         * @param vehicleIds
         */
        uaLib.removeCustomOrderVehicles = function (vehicleIds) {
            let removeVehicleForm = {ids: vehicleIds};

            return apiUserCallPromise(options.customOrderVehiclePath, "DELETE", removeVehicleForm, UAEventEnum.CUSTOM_ORDER_VEHICLE_REMOVED);
        };

        /**
         * Subscribe logged in user to email alerts
         * @returns {Promise}
         */
        uaLib.subscribe = function (email) {

            let updateUserAccountForm = {
                email: email
            };
            return apiUserCallPromise(options.subscribePath, "POST", updateUserAccountForm,
                UAEventEnum.USER_ACCOUNT_SUBSCRIBED);
        };

        /**
         * Unsubscribe logged in user to email alerts
         * @returns {Promise}
         */
        uaLib.unsubscribe = function () {

            return apiUserCallPromise(options.unsubscribePath, "POST", null,
                UAEventEnum.USER_ACCOUNT_UNSUBSCRIBED);
        };

        uaLib.updateLanguage = (language) => {
            return updateUserAccount({language: language});
        };

        uaLib.updateLocation = (provinceCode, latitude, longitude, postalCode) => {
            return updateUserAccount({
                provinceCode: provinceCode,
                latitude: latitude,
                longitude: longitude,
                postalCode: postalCode
            });
        };

        uaLib.submitPopupForm = function (email, postalCode) {
            return new Promise((resolve, reject) => {
                let loginForm = {
                    email: email,
                    postalCode: postalCode,
                    authenticationProvider: 'STANDALONE'
                };

                let callback = (response) => {
                    resolve(response);
                };

                login(loginForm, callback);
            });
        };

        uaLib.googleSignin = function () {

            return new Promise((resolve, reject) => {

                let callback = (response) => {
                    resolve(response);
                };

                gapi.load('auth2', function () {
                    // Retrieve the singleton for the GoogleAuth library and set up the client.
                    let auth2 = gapi.auth2.init({
                        client_id: options.googleClientId
                    });
                    auth2.attachClickHandler(options.googleLoginButtonId,
                        {},
                        function (googleUser) {
                            let profile = googleUser.getBasicProfile();
                            let loginForm = {
                                email: profile.getEmail(),
                                authenticationProvider: 'GOOGLE',
                                externalId: profile.getId()
                            };
                            login(loginForm, callback);
                        },
                        function (error) {
                            log(JSON.stringify(error, undefined, 2));
                        });

                });
            });
        };

        uaLib.facebookSignin = function () {
            return new Promise((resolve, reject) => {
                FB.login(function (response) {
                    if (response.status === 'connected') {
                        //var accessToken = response.authResponse.accessToken;
                        //get profile info
                        FB.api('/me', {fields: 'id,email'}, function (response) {
                            let loginForm = {
                                email: response.email,
                                authenticationProvider: 'FACEBOOK',
                                externalId: response.id
                            };

                            let callback = (response) => {
                                resolve(response);
                            };

                            login(loginForm, callback);

                        });
                    } else {
                        log('User cancelled login or did not fully authorize.');
                    }
                });
            });
        };

        uaLib.resetUserData = () => {
            clearSessionStorage();
            sessionStorage.removeItem(options.verifiedStorageKey);
        };

        ///////////////////////////////////////////////////////////////////////
        //
        //
        //                        PRIVATE FUNCTIONS
        //
        //
        ///////////////////////////////////////////////////////////////////////

        let getVehicles = function (path, key, uaEvent) {

            return new Promise((resolve, reject) => {
                let vehicles = JSON.parse(sessionStorage.getItem(key));
                if (vehicles === null) {
                    apiUserCallPromise(path, "GET", null, uaEvent).then((response) => resolve(response), reject);
                } else {
                    resolve(vehicles);
                }
            });
        };

        let getOtherUsersViews = function (path, key, uaEvent, vin) {

            return new Promise((resolve, reject) => {

                let contextualizedPath = path.replace('{vin}', vin);

                if (uaLib.isUserLoggedIn()) {
                    contextualizedPath = contextualizedPath.replace('{id}', uaLib.getUser().userAccountId);
                } else {
                    contextualizedPath = contextualizedPath.replace('{id}/', "");
                }

                $.ajax({
                    type: "GET",
                    contentType: "application/json",
                    url: window.FCA_SITES_CONFIG.userAccountBaseUrl + contextualizedPath,
                    data: '',
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function (response) {
                        if (isSuccess(response)) {
                            resolve(response.data);
                        } else {
                            reject({message: 'Error while getting other users count views for vin : ' + vin + '\n' + response.message});
                        }
                    }
                });
            });
        };

        let updateUserAccount = (updateUserAccountForm) => {

            return apiUserCallPromise(options.userAccountPath, "POST", updateUserAccountForm,
                UAEventEnum.USER_ACCOUNT_UPDATED);
        };

        /**
         * Login user in User Account App backend
         */
        let login = function (loginForm, callbackAfterLogin) {
            //we determinate language and provinceCode (they are saved at the first connection)
            let language = $('html').attr('lang');
            if (!language) {
                language = DEFAULT_LANGUAGE;
            }
            let provinceCode = null;
            if (!provinceCode) {
                provinceCode = DEFAULT_PROVINCE_CODE;
            }
            $.extend(loginForm, {
                language: language,
                provinceCode: provinceCode
            });

            $.ajax({
                type: "POST",
                contentType: "application/json",
                url: options.apiBaseUrl + options.loginPath,
                data: JSON.stringify(loginForm),
                xhrFields: {
                    withCredentials: true
                },
                success: function (response) {
                    if (isSuccess(response)) {
                        handleEvent(UAEventEnum.LOGIN, response.data);
                        executeIfDefinedWithOneParam(callbackAfterLogin, response);
                    } else {
                        executeIfDefinedWithOneParam(callbackAfterLogin, response);
                    }
                },
                error: function (e) {
                    log(['Error in response during login: ', e.responseText]);
                    executeIfDefinedWithOneParam(callbackAfterLogin, {
                        status: 'FAIL',
                        message: e.responseText
                    });
                }
            });
        };

        /**
         * Standalone initialization
         */
        let initStandalone = function (callbackAfterInit) {

            let standaloneAuthButton = $('#' + options.standaloneLoginButtonId);
            if (standaloneAuthButton.length) {
                standaloneAuthButton.on('click', function () {
                    let loginForm = {
                        email: $('#' + options.emailInputId).val(),
                        postalCode: $('#' + options.postalCodeInputId).val(),
                        authenticationProvider: 'STANDALONE'
                    };
                    login(loginForm, callbackAfterInit);
                });
            }
        };

        /**
         * Google initialization
         */
        let initGoogle = function (callbackAfterInit) {

            // import google library
            (function (d, s, id) {
                let js, gjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://apis.google.com/js/platform.js?onload=initGoogleAuth";
                gjs.parentNode.insertBefore(js, gjs);
            }(document, 'script', 'google-jssdk'));

            // promise that would be resolved when gapi would be loaded
            let gapiPromise = (function () {
                let deferred = $.Deferred();
                window.initGoogleAuth = function () {
                    deferred.resolve(gapi);
                };
                return deferred.promise()
            }());

            let googleButton = $('#' + options.googleLoginButtonId);

            gapiPromise.then(function () {
                gapi.load('auth2', function () {
                    // Retrieve the singleton for the GoogleAuth library and set up the client.
                    let auth2 = gapi.auth2.init({
                        client_id: options.googleClientId
                    });

                    // no handler to attach if the button is not present in the page
                    if (googleButton.length) {
                        auth2.attachClickHandler(options.googleLoginButtonId,
                            {},
                            function (googleUser) {
                                let profile = googleUser.getBasicProfile();
                                let loginForm = {
                                    email: profile.getEmail(),
                                    authenticationProvider: 'GOOGLE',
                                    externalId: profile.getId()
                                };
                                login(loginForm, callbackAfterInit);
                            },
                            function (error) {
                                log(JSON.stringify(error, undefined, 2));
                            });
                    }
                });
            });
        };

        /**
         * Facebook initialization
         */
        let initFacebook = function (callbackAfterInit) {

            let facebookButton = $('#' + options.facebookLoginButtonId);

            window.fbAsyncInit = function () {
                FB.init({
                    appId: options.facebookAppId,
                    cookie: true,
                    xfbml: true,
                    version: 'v2.12'
                });
            };

            // import facebook library
            (function (d, s, id) {
                let js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));

            // add trigger on div which contains facebook button to handle the authentication
            facebookButton.on('click',
                function () {
                    FB.login(function (response) {
                        if (response.status === 'connected') {
                            //var accessToken = response.authResponse.accessToken;
                            //get profile info
                            FB.api('/me', {fields: 'id,email'}, function (response) {
                                let loginForm = {
                                    email: response.email,
                                    authenticationProvider: 'FACEBOOK',
                                    externalId: response.id
                                };
                                login(loginForm, callbackAfterInit);

                            });
                        } else {
                            log('User cancelled login or did not fully authorize.');
                        }
                    }, {scope: 'email'});
                });
        };

        /**
         * Return true when response.status === 'SUCCESS'
         * @param response
         * @returns {boolean}
         */
        let isSuccess = function (response) {
            return response !== '' && response.status === 'SUCCESS';
        };

        /**
         * After a successfull api response, this function can do the specific stuff
         * @param event
         * @param data data corresponding to the event (may be null)
         */
        let handleEvent = function (event, data) {
            if (event !== null) {

                switch (event) {

                    // Authentication events
                    case UAEventEnum.LOGIN:
                    case UAEventEnum.USER_ACCOUNT_SUBSCRIBED:
                    case UAEventEnum.USER_ACCOUNT_UNSUBSCRIBED:
                    case UAEventEnum.USER_ACCOUNT_UPDATED:
                        //add user to session storage
                        window.FcaCookieChecker.addSessionStorage(options.userStorageKey, JSON.stringify(data));
                        break;

                    // Inventory vehicle events
                    case UAEventEnum.INVENTORY_VEHICLE_ALL_RETRIEVED:
                        window.FcaCookieChecker.addSessionStorage(options.inventoryVehiclesStorageKey, JSON.stringify(data));
                        break;
                    case UAEventEnum.INVENTORY_VEHICLE_ADDED:
                    case UAEventEnum.INVENTORY_VEHICLE_REMOVED:
                    case UAEventEnum.INVENTORY_VEHICLE_UPDATED:
                        sessionStorage.removeItem(options.inventoryVehiclesStorageKey);
                        break;

                    // Built vehicle events
                    case UAEventEnum.BUILT_VEHICLE_ALL_RETRIEVED:
                        window.FcaCookieChecker.addSessionStorage(options.builtVehiclesStorageKey, JSON.stringify(data));
                        break;
                    case UAEventEnum.BUILT_VEHICLE_ADDED:
                    case UAEventEnum.BUILT_VEHICLE_REMOVED:
                    case UAEventEnum.BUILT_VEHICLE_UPDATED:
                        sessionStorage.removeItem(options.builtVehiclesStorageKey);
                        break;

                    // Custom vehicle events
                    case UAEventEnum.CUSTOM_ORDER_VEHICLE_ALL_RETRIEVED:
                        window.FcaCookieChecker.addSessionStorage(options.customOrderVehiclesStorageKey, JSON.stringify(data));
                        break;
                    case UAEventEnum.CUSTOM_ORDER_VEHICLE_ADDED:
                    case UAEventEnum.CUSTOM_ORDER_VEHICLE_REMOVED:
                    case UAEventEnum.CUSTOM_ORDER_VEHICLE_UPDATED:
                        sessionStorage.removeItem(options.customOrderVehiclesStorageKey);
                        break;

                    // Viewed vehicle events
                    case UAEventEnum.VIEWED_VEHICLE_ALL_RETRIEVED:
                        window.FcaCookieChecker.addSessionStorage(options.viewedVehiclesStorageKey, JSON.stringify(data));
                        break;
                    case UAEventEnum.VIEWED_VEHICLE_ADDED:
                    case UAEventEnum.VIEWED_VEHICLE_REMOVED:
                        sessionStorage.removeItem(options.viewedVehiclesStorageKey);
                        break;
                    case UAEventEnum.VIEWED_VEHICLE_OTHER_USERS:
                }

                if (event !== UAEventEnum.VIEWED_VEHICLE_ALL_RETRIEVED
                    && event !== UAEventEnum.INVENTORY_VEHICLE_ALL_RETRIEVED
                    && event !== UAEventEnum.BUILT_VEHICLE_ALL_RETRIEVED
                    && event !== UAEventEnum.VIEWED_VEHICLE_ALL_RETRIEVED
                    && event !== UAEventEnum.CUSTOM_ORDER_VEHICLE_ALL_RETRIEVED) {

                    //we call the common domain iframe ti update the last modification date for the current domain
                    const iframe = document.getElementById('fca-ua-iframe');
                    if (iframe) {
                        iframe.contentWindow.postMessage({
                                eventId: 'ua_iframe_data_updated'
                            },
                            options.apiBaseUrl);
                    }
                }
            }
        };

        /**
         * Helper method to make rest calls to the backend
         */
        let api = function (path, method, objectData, successCallback, errorCallback) {
            log(['Request:', method, path, objectData]);

            $.ajax({
                type: method,
                contentType: "application/json",
                url: options.apiBaseUrl + path,
                data: !$.isEmptyObject(objectData) ? JSON.stringify(objectData) : '',
                xhrFields: {
                    withCredentials: true
                },
                success: successCallback,
                error: errorCallback,
                complete: function (jqXhr) {
                    log(['Response:', jqXhr.status, jqXhr.responseText]);
                }
            });
        };

        /**
         * Make a call to the backend only if there is a user in session storage.
         * If path contains '{id}' string, it will be replace by the userId in session storage.
         */
        let apiUserCallPromise = function (path, method, objectData, eventEnum) {

            return new Promise((resolve, reject) => {

                if (uaLib.isUserLoggedIn()) {

                    let contextualizedPath = path.replace('{id}', uaLib.getUser().userAccountId);

                    api(
                        contextualizedPath,
                        method,
                        objectData,
                        (response) => {
                            if (isSuccess(response)) {
                                if (eventEnum !== null) {
                                    handleEvent(eventEnum, response.data);
                                }
                                resolve(response.data);
                            } else {
                                reject({message: 'Response status is not \'SUCCESS\': ' + response.message});
                            }
                        },
                        (e) => {
                            //if we obtain a 403, that means user is logged out so we clear session storage.
                            if (e.status === 403) {
                                clearSessionStorage();
                            }
                            reject({message: e.responseText})
                        }
                    );
                } else {

                    log('No users are present in session storage and so no calls will be done!');

                    reject({message: 'No users are connected'});
                }
            });
        };

        /**
         * Clear all data properties set in local storage
         */
        let clearSessionStorage = function () {
            sessionStorage.removeItem(options.userStorageKey);
            sessionStorage.removeItem(options.inventoryVehiclesStorageKey);
            sessionStorage.removeItem(options.builtVehiclesStorageKey);
            sessionStorage.removeItem(options.viewedVehiclesStorageKey);
            sessionStorage.removeItem(options.customOrderVehiclesStorageKey);
            //sessionStorage.removeItem(options.verifiedStorageKey);
        };

        /**
         * Return true if the verification has been done (when the user doesn't have an account, we don't want trying to get account at each page displayed)
         * @returns {boolean}
         */
        let isUserVerified = function () {
            return sessionStorage.getItem(options.verifiedStorageKey) === 'true';
        };

        /**
         * Initialize the iframe used for communicate with clients and user account.
         */
        let initIFrame = function () {

            if (options.iframeDivId !== '') {
                const div = $('#' + options.iframeDivId);
                if (div.length > 0) {
                    //create iframe
                    const iframe = $(document.createElement('iframe'))
                    .prop('src', options.apiBaseUrl + options.iframePath)
                    .prop('style',
                        'position: absolute; width: 1px; height: 1px; left: -9999px; top: -9999px; right: -9999px; bottom: -9999px; display: none;')
                    .prop('id', 'fca-ua-iframe');

                    //add iframe to the client div
                    div.append(iframe);
                }
            }

            //declare a listener to handle messages from fca-ua-iframe
            window.addEventListener('message', (event) => {

                if (event.origin === options.apiBaseUrl) {

                    log(['Event from ua iframe with data:', JSON.stringify(event.data)]);

                    executeIfDefinedWithOneParam(options.onMessageFromIFrame, event.data);

                } else {
                    // something from an unknown domain, let's ignore it
                }
            });
        };

        /**
         * Log the message if options.debug = true
         * @param something an array, a string, an object, ...
         */
        let log = function (something) {
            if (options.debug === 'true') {
                let message = '';
                if ($.isArray(something)) {
                    $.each(something, function (index, value) {
                        if ($.type(value) === 'object') {
                            message += JSON.stringify(value) + ' ';
                        } else {
                            message += value + ' ';
                        }
                    });
                } else {
                    message = something;
                }
                console.debug('UA -> ' + message);
            }
        };

        return uaLib;
    }

// We need that our library is globally accesible, then we save in the window
    if (typeof(window.UA) === 'undefined') {
        window.UA = userAccountLib();
    }

})
(window); // We send the window variable withing our function

/**
 * Execute the function past in parameters if it is defined
 * @param callback a function
 */
function executeIfDefined(callback) {
    if ($.isFunction(callback)) {
        callback();
    }
}

/**
 * Execute the function past in parameters if it is defined
 * @param callback a function
 * @param param1 a parameter
 */
function executeIfDefinedWithOneParam(callback, param1) {
    if ($.isFunction(callback)) {
        callback(param1);
    }
}
